<template>
  <div>
    <p>NewsTrend.vue</p>
  </div>
</template>

<script>
export default {
  props: {
    newsParam: Object,
    dataviewValue: Object,
  },
  data() {
    return {
      perf: null,
    }
  },
  created() {
  },
  mounted() {
    console.log('NewsTrend.vue mounted');
    if(!this.dataviewValue){
      this.$emit('refreshNewsData', this.$route.params.code);
    }else{
      console.log('newsData already exists');
    }
    this.$emit('setCurrentTabID', 1);
  },
}
</script>